<script setup>
import { Head, useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => {
            form.reset('password')
        }
    });
};
</script>

<template>
    <Head title="Logg inn" />
    <AuthLayout >
        <form @submit.prevent="submit" class="space-y-4">
            <div class="text-center space-y-4">
                <h2 class="font-bold text-heading-2">Logg inn</h2>
            </div>
            <div>
                <FormInput id="email" type="email" v-model="form.email" :error="form.errors.email" label="E-post" required autofocus autocomplete="username"/>
            </div>
            <div>
                <FormInput id="Password" type="password" v-model="form.password" :error="form.errors.password" label="Passord" required autocomplete="current-password"/>
            </div>

            <div class="">
                <FormCheckbox v-model:checked="form.remember" label="Husk meg" name="remember" />
            </div>
            <div class="flex flex-col items-center mt-4 gap-2">
                <FormButton type="submit" :disabled="form.processing" >
                    <span>Logg inn</span>
                </FormButton>
                <a :href="route('password.request')" class="btn--default btn--ghost">Glemt passord?</a>
            </div>
        </form>
    </AuthLayout>
</template>
